<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="2" class="">
            <label>Mostrar</label>
            <v-select v-model="tableSettings.perPage" :options="perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
          </b-col>

          <b-col md="2">
            <b-form-group>
              <v-select class="d-inline" v-model="tableSettings.input" label="title" :options="inputs"
                :reduce="val => val.value" placeholder="Título" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <v-select class="d-inline" v-model="tableSettings.typeSearch" label="title" :options="typeSearch"
                :reduce="val => val.value" placeholder="Tipo de búsqueda" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <b-form-input v-model="tableSettings.searchQuery" class="d-inline" style="width: 90%"
                placeholder="Buscar..." />
            </b-form-group>
          </b-col>


          <!-- Search -->
          <b-col cols="12" md="2">
            <div class="align-items-center justify-content-end">
              <b-button variant="primary" @click="searchData(tableSettings)" class="btn-icon rounded-circle mr-1">
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-button variant="primary" class="btn-icon rounded-circle mr-1" @click="isAddNewUserSidebarActive = true">
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button variant="danger" class="btn-icon rounded-circle mr-1" @click="downloadExcel()">
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <b-button variant="info" class="btn-icon rounded-circle" @click="uploadExcel(action = 'uploadExcel')">
                <feather-icon icon="UploadIcon" />
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table ref="refRoleListTable" class="position-relative" :items="dataTable" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
        :sort-desc.sync="tableSettings.sortDesc">
        <template #cell(role)="data">
          <div v-for="(item, index) in data.item.role" :key="index">
            <span>{{ item }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(sum)="data">
          <span class="text-center">{{ data.item.recovery_value }}</span>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button @click="showRecovery(data.item, action = 'show')" variant="primary"
              class="btn-icon rounded-circle mr-1" v-b-tooltip.hover.v-primary title="Ver">
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button @click="showRecovery(data.item, action = 'edit')" variant="primary" class="btn-icon rounded-circle"
              v-b-tooltip.hover.v-primary title="Ver">
              <feather-icon icon="EditIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Viendo del {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }}
              registros</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination v-model="tableSettings.page" :total-rows="totalRows" :per-page="tableSettings.perPage"
              first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal v-model="showModal" title="Recobro" ok-only size="xl" ref="my-modal" ok-title="Cerrar">
      <!-- ver formulario -->
      <b-card-text v-if="action == 'show'">
        <b-row>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Recobro Sin prefijo" label-for="basicInput">
              <b-form-input :placeholder="form.recobro_sin_prefijo" v-model="form.recobro_sin_prefijo" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="NIT" label-for="basicInput">
              <b-form-input :placeholder="form.nit" v-model="form.nit" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="NOMBRE" label-for="basicInput">
              <b-form-input :placeholder="form.nonbre" v-model="form.nonbre" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Recobro con prefijo" label-for="basicInput">
              <b-form-input :placeholder="form.recobro_con_prefijo" v-model="form.recobro_con_prefijo" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="No Radicado Ente" label-for="basicInput">
              <b-form-input :placeholder="form.no_Radicado_Ente" v-model="form.no_Radicado_Ente" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="FECFAC" label-for="basicInput">
              <b-form-input :placeholder="form.fecfac" v-model="form.fecfac" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="FECHA PRESTACION" label-for="basicInput">
              <b-form-input :placeholder="form.fecha_prestacion" v-model="form.fecha_prestacion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="EDAD" label-for="basicInput">
              <b-form-input :placeholder="form.edad" v-model="form.edad" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="INFORME EDAD" label-for="basicInput">
              <b-form-input :placeholder="form.informe_edad" v-model="form.informe_edad" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="PRESCRIPCION" label-for="basicInput">
              <b-form-input :placeholder="form.prescripcion" v-model="form.prescripcion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="CORTE" label-for="basicInput">
              <b-form-input :placeholder="form.corte" v-model="form.corte" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Entidad Responsable del Pago" label-for="basicInput">
              <b-form-input :placeholder="form.entidad_Responsable_del_Pago" v-model="form.entidad_Responsable_del_Pago"
                disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="RECIBOS CAJA / NOTAS" label-for="basicInput">
              <b-form-input :placeholder="form.recibos_cajas_notas" v-model="form.recibos_cajas_notas" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Firma Correa y Cortes" label-for="basicInput">
              <b-form-input :placeholder="form.firma_Correa_y_Cortes" v-model="form.firma_Correa_y_Cortes" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Firma Bilcoll" label-for="basicInput">
              <b-form-input :placeholder="form.firma_Bilcoll" v-model="form.firma_Bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="ENTREGA ABOGADOS EXT" label-for="basicInput">
              <b-form-input :placeholder="form.entrega_abogados_ext" v-model="form.entrega_abogados_ext" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="VIGENCIA BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.vigencia_bilcoll" v-model="form.vigencia_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="EDAD BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.edad_bilcoll" v-model="form.edad_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="PRESCRIPCION BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.precispcion_bilcoll" v-model="form.precispcion_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="FECHA ENTREGA BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.fehca_entrega_bilcoll" v-model="form.fehca_entrega_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="SALDO" label-for="basicInput">
              <b-form-input :placeholder="form.saldo" v-model="form.saldo" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Vr Aceptado" label-for="basicInput">
              <b-form-input :placeholder="form.vr_Aceptado" v-model="form.vr_Aceptado" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Vr Cancelado" label-for="basicInput">
              <b-form-input :placeholder="form.vr_Cancelado" v-model="form.vr_Cancelado" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Observacion" label-for="basicInput">
              <b-form-input :placeholder="form.observacion" v-model="form.observacion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="GLOSA CARTERA RECOBROS" label-for="basicInput">
              <b-form-input :placeholder="form.glosa_cartera_recobros" v-model="form.glosa_cartera_recobros" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="GLOSA EN CONCILIACION" label-for="basicInput">
              <b-form-input :placeholder="form.golsa_en_conciliacion" v-model="form.golsa_en_conciliacion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="PROCESO JURIDICO" label-for="basicInput">
              <b-form-input :placeholder="form.proceso_juridico" v-model="form.proceso_juridico" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="SIN RTA AUDITORIA ENT" label-for="basicInput">
              <b-form-input :placeholder="form.sin_rta_auditoria_ente" v-model="form.sin_rta_auditoria_ente" disabled />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
      <!-- editar formulario -->
      <b-card-text v-if="action == 'edit'">
        <b-row>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Recobro Sin prefijo" label-for="basicInput">
              <b-form-input :placeholder="form.recobro_sin_prefijo" v-model="form.recobro_sin_prefijo" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="NIT" label-for="basicInput">
              <b-form-input :placeholder="form.nit" v-model="form.nit" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="NOMBRE" label-for="basicInput">
              <b-form-input :placeholder="form.nonbre" v-model="form.nonbre" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Recobro con prefijo" label-for="basicInput">
              <b-form-input :placeholder="form.recobro_con_prefijo" v-model="form.recobro_con_prefijo" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="No Radicado Ente" label-for="basicInput">
              <b-form-input :placeholder="form.no_Radicado_Ente" v-model="form.no_Radicado_Ente" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="FECFAC" label-for="basicInput">
              <b-form-input :placeholder="form.fecfac" v-model="form.fecfac" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="FECHA PRESTACION" label-for="basicInput">
              <b-form-input :placeholder="form.fecha_prestacion" v-model="form.fecha_prestacion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="EDAD" label-for="basicInput">
              <b-form-input :placeholder="form.edad" v-model="form.edad" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="INFORME EDAD" label-for="basicInput">
              <b-form-input :placeholder="form.informe_edad" v-model="form.informe_edad" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="PRESCRIPCION" label-for="basicInput">
              <b-form-input :placeholder="form.prescripcion" v-model="form.prescripcion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="CORTE" label-for="basicInput">
              <b-form-input :placeholder="form.corte" v-model="form.corte" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Entidad Responsable del Pago" label-for="basicInput">
              <b-form-input :placeholder="form.entidad_Responsable_del_Pagdisabledo"
                v-model="form.entidad_Responsable_del_Pago" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="RECIBOS CAJA / NOTAS" label-for="basicInput">
              <b-form-input :placeholder="form.recibos_cajas_notas" v-model="form.recibos_cajas_notas" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Firma Correa y Cortes" label-for="basicInput">
              <b-form-input :placeholder="form.firma_Correa_y_Cortes" v-model="form.firma_Correa_y_Cortes" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Firma Bilcoll" label-for="basicInput">
              <b-form-input :placeholder="form.firma_Bilcoll" v-model="form.firma_Bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="ENTREGA ABOGADOS EXT" label-for="basicInput">
              <b-form-input :placeholder="form.entrega_abogados_ext" v-model="form.entrega_abogados_ext" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="VIGENCIA BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.vigencia_bilcoll" v-model="form.vigencia_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="EDAD BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.edad_bilcoll" v-model="form.edad_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="PRESCRIPCION BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.precispcion_bilcoll" v-model="form.precispcion_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="FECHA ENTREGA BILCOLL" label-for="basicInput">
              <b-form-input :placeholder="form.fehca_entrega_bilcoll" v-model="form.fehca_entrega_bilcoll" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="SALDO" label-for="basicInput">
              <b-form-input :placeholder="form.saldo" v-model="form.saldo" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Vr Aceptado" label-for="basicInput">
              <b-form-input :placeholder="form.vr_Aceptado" v-model="form.vr_Aceptado" />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Vr Cancelado" label-for="basicInput">
              <b-form-input :placeholder="form.vr_Cancelado" v-model="form.vr_Cancelado" />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="4" class="mb-1">
            <b-form-group label="Observacion" label-for="basicInput">
              <b-form-input :placeholder="form.observacion" v-model="form.observacion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="GLOSA CARTERA RECOBROS" label-for="basicInput">
              <b-form-input :placeholder="form.glosa_cartera_recobros" v-model="form.glosa_cartera_recobros" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="GLOSA EN CONCILIACION" label-for="basicInput">
              <b-form-input :placeholder="form.golsa_en_conciliacion" v-model="form.golsa_en_conciliacion" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="PROCESO JURIDICO" label-for="basicInput">
              <b-form-input :placeholder="form.proceso_juridico" v-model="form.proceso_juridico" disabled />
            </b-form-group>
          </b-col>
          <b-col md="6" xl="3" class="mb-1">
            <b-form-group label="SIN RTA AUDITORIA ENT" label-for="basicInput">
              <b-form-input :placeholder="form.sin_rta_auditoria_ente" v-model="form.sin_rta_auditoria_ente" disabled />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col md="12" xl="12" class="text-center">
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mr-2"
            @click="updateRecobro()">
            Actualizar
          </b-button>
        </b-col>
      </b-card-text>
      <b-card-text v-if="action == 'uploadExcel'">
        <b-row>
          <b-col sm="4" offset="4">
            <label for="wildcard">Cargar Excel</label>
            <b-form-file v-model="importForm.file" id="wildcard" accept=".xlsx, .xlsm, .xlsb, .xltx"
              :browse-text="customBrowseText" />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="text-center">
            <b-button variant="primary" class="mt-2" v-b-tooltip.hover.v-primary title="Ver" @click="importExcel()">
              Cargar Excel
            </b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>

    <template v-if="showtable">
      <b-card no-body class="mb-5 mt-5">
        <b-row>
          <b-col sm="12" class="text-right">
            <h1 class="text-center mt-5 mb-3">Tabla De Errores</h1>
            <b-button variant="primary" @click="downloadErrors()" class="m-2">
              <feather-icon icon="DownloadIcon" /> Descargar
            </b-button>
          </b-col>
        </b-row>
        <b-table ref="refRoleListTable" class="position-relative" :items="errors" responsive :fields="tableColumnsErrors"
          primary-key="id" :sort-by.sync="tableSettings.sortBy" show-empty empty-text="No se encontraron datos"
          :sort-desc.sync="tableSettings.sortDesc">
          <template #cell(row)="data">
            <span class="text-center">{{ data.item.row }}</span>
          </template>
        </b-table>
      </b-card>
    </template>
  </div>
</template>
    
<script>
extend('required', {
  ...required,
  message: 'El campo {_field_} es obligatorio'
});
import { extend } from 'vee-validate'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, VBTooltip, BCardText, BFormFile
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import es from 'vee-validate/dist/locale/es'
export default {
  name: 'Recoveries',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    VBTooltip,
    BCardText,
    BFormFile
  },
  data() {
    return {
      showtable: false,
      importForm: {
        file: null,
      },
      customBrowseText: 'Seleccionar',
      action: null,
      id_recovery: this.$route.params && this.$route.params.id_recovery,
      form: {
        id: null,
        recobro_sin_prefijo: null,
        nit: null,
        nonbre: null,
        recobro_con_prefijo: null,
        no_Radicado_Ente: null,
        fecfac: null,
        fecha_prestacion: null,
        edad: null,
        informe_edad: null,
        prescripcion: null,
        corte: null,
        entidad_Responsable_del_Pago: null,
        recibos_cajas_notas: null,
        firma_Correa_y_Cortes: null,
        firma_Bilcoll: null,
        entrega_abogados_ext: null,
        vigencia_bilcoll: null,
        edad_bilcoll: null,
        precispcion_bilcoll: null,
        fehca_entrega_bilcoll: null,
        saldo: null,
        vr_Aceptado: null,
        vr_Cancelado: null,
        observacion: null,
        glosa_cartera_recobros: null,
        golsa_en_conciliacion: null,
        proceso_juridico: null,
        sin_rta_auditoria_ente: null,
      },
      showModal: false,
      update: false,
      modal_password: false,
      formDisabled: false,
      isAddNewUserSidebarActive: false,
      refRoleListTable: null,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      inputs: [
        { value: 'recovery_with_prefix', title: 'Número de recobro' },
        { value: 'radiated_number', title: 'Número de radicado' },
      ],
      typeSearch: [
        { value: 'LIKE', title: 'Igual' },
        { value: 'NOT LIKE', title: 'No es igual' },
        { value: '>', title: 'Mayor que' },
        { value: '<', title: 'Menor que' }
      ],
      tableColumns: [
        { key: 'recovery_without_prefix', label: 'Recobro Sin prefijo', sortable: true },
        { key: 'nit', label: 'NIT', sortable: true },
        { key: 'name', label: 'NOMBRE' },
        { key: 'recovery_with_prefix', label: 'Recobro con prefijo' },
        { key: 'radiated_number', label: 'No Radicado Ente' },
        { key: 'FECFAC', label: 'FECFAC' },
        { key: 'benefit_date', label: 'FECHA PRESTACION' },
        { key: 'age', label: 'EDAD' },
        { key: 'age_inform', label: 'INFORME EDAD' },
        { key: 'prescription', label: 'PRESCRIPCION' },
        { key: 'court', label: 'CORTE' },
        { key: 'entity_responsible_for_payment', label: 'Entidad Responsable del Pago' },
        { key: 'receipts_box_notes', label: 'RECIBOS CAJA / NOTAS' },
        { key: 'signature_correa_cortes', label: 'Firma Correa y Cortes' },
        { key: 'bilcoll_signature', label: 'Firma Bilcoll' },
        { key: 'delivers_ext_lawyers', label: 'ENTREGA ABOGADOS EXT' },
        { key: 'billcol_validity', label: 'VIGENCIA BILCOLL' },
        { key: 'billcol_age', label: 'EDAD BILCOLL' },
        { key: 'billcol_prescription', label: 'PRESCRIPCION BILCOLL' },
        { key: 'billcol_delivery_date', label: 'FECHA ENTREGA BILCOLL' },
        { key: 'balance', label: 'SALDO' },
        { key: 'vr_accepted', label: 'Vr Aceptado' },
        { key: 'vr_canceled', label: 'Vr Cancelado' },
        { key: 'observation', label: 'Observacion' },
        { key: 'gloss_portfolio_recoveries', label: 'GLOSA CARTERA RECOBROS' },
        { key: 'conciliation_gloss', label: 'GLOSA EN CONCILIACION' },
        { key: 'legal_process', label: 'PROCESO JURIDICO' },
        { key: 'without_rta_audit', label: 'SIN RTA AUDITORIA ENTE' },
        { key: 'recovery_value', label: 'Valor recobro' },
        { key: 'actions', label: 'Acciones' }
      ],
      tableColumnsErrors: [
        { key: 'row', label: 'Fila', sortable: true },
        { key: 'column', label: 'Nombre Columna', sortable: true },
        { key: 'message', label: 'Mensaje', sortable: true },
        { key: 'value', label: 'Valor', sortable: true }
      ],
      sortBy: 'id',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      showLoadingTable: false,
      tableSettings: {
        filter: this.$route.params && this.$route.params.id,
        searchQuery: '',
        input: '',
        typeSearch: '',
        perPage: 10,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      },
      formDataEdit: {},
      recoveries: [],
      errors: [],
    }
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  watch: {
    "tableSettings.sortBy": {
      handler(val) {
        this.fetchRecoveries()

      },
    },
    "tableSettings.sortDesc": {
      handler(val) {
        this.fetchRecoveries()

      },
    },
    "tableSettings.perPage": {
      handler(val) {
        this.fetchRecoveries()

      },
    },
    "tableSettings.page": {
      handler(val) {
        this.fetchRecoveries()

      },
    },
  },
  created() {
    this.fetchRecoveries()
  },
  methods: {
    downloadErrors() {
      this.$http.post('/recoveries/download-excel-errors', this.errors,
        { responseType: 'blob' }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'recobros.xlsx');
          document.body.appendChild(link);
          link.click();
          this.$swal({
            icon: "success",
            title: "Éxito",
            html: "Archivo descargado éxitosamente!",
          });
        })
    },
    importExcel() {
      const formData = new FormData();
      formData.append('file', this.importForm.file);
      this.$http.post('/recoveries/importExcel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          if (response.status === 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.fetchRecoveries()
            this.hideModal()
            if (response.data.code === 500) {
              this.$swal({
                title: response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                buttonsStyling: false,
              });
              this.errors = response.data.errors
              this.showtable = true
            }
          }
        })
        .catch(error => {
          console.error('Error en la solicitud:', error);
          if (error.status === 500) {
            console.error('Error interno del servidor:', error.data);
          }
        });
    },
    uploadExcel(action) {
      this.showModal = true
    },
    updateRecobro() {
      this.$http.post('/recoveries/update', this.form)
        .then(response => {
          if (response.data.code == 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.fetchRecoveries()
            this.hideModal()
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    showRecovery(data, action) {
      this.showModal = true
      if (action == 'show') {
        this.form = {
          id: data.id,
          recobro_sin_prefijo: data.recovery_without_prefix,
          nit: data.nit,
          nonbre: data.name,
          recobro_con_prefijo: data.recovery_with_prefix,
          no_Radicado_Ente: data.radiated_number,
          fecfac: data.FECFAC,
          fecha_prestacion: data.benefit_date,
          edad: data.age,
          informe_edad: data.age_inform,
          prescripcion: data.prescription,
          corte: data.court,
          entidad_Responsable_del_Pago: data.entity_responsible_for_payment,
          recibos_cajas_notas: data.receipts_box_notes,
          firma_Correa_y_Cortes: data.signature_correa_cortes,
          firma_Bilcoll: data.bilcoll_signature,
          entrega_abogados_ext: data.delivers_ext_lawyers,
          vigencia_bilcoll: data.billcol_validity,
          edad_bilcoll: data.billcol_age,
          precispcion_bilcoll: data.billcol_prescription,
          fehca_entrega_bilcoll: data.billcol_delivery_date,
          saldo: data.balance,
          vr_Aceptado: data.vr_accepted,
          vr_Cancelado: data.vr_canceled,
          observacion: data.observation,
          glosa_cartera_recobros: data.gloss_portfolio_recoveries,
          golsa_en_conciliacion: data.conciliation_gloss,
          proceso_juridico: data.legal_process,
          sin_rta_auditoria_ente: data.without_rta_audit,
        }
      }
      if (action == 'edit') {
        this.form = {
          id: data.id,
          recobro_sin_prefijo: data.recovery_without_prefix,
          nit: data.nit,
          nonbre: data.name,
          recobro_con_prefijo: data.recovery_with_prefix,
          no_Radicado_Ente: data.radiated_number,
          fecfac: data.FECFAC,
          fecha_prestacion: data.benefit_date,
          edad: data.age,
          informe_edad: data.age_inform,
          prescripcion: data.prescription,
          corte: data.court,
          entidad_Responsable_del_Pago: data.entity_responsible_for_payment,
          recibos_cajas_notas: data.receipts_box_notes,
          firma_Correa_y_Cortes: data.signature_correa_cortes,
          firma_Bilcoll: data.bilcoll_signature,
          entrega_abogados_ext: data.delivers_ext_lawyers,
          vigencia_bilcoll: data.billcol_validity,
          edad_bilcoll: data.billcol_age,
          precispcion_bilcoll: data.billcol_prescription,
          fehca_entrega_bilcoll: data.billcol_delivery_date,
          saldo: data.balance,
          vr_Aceptado: data.vr_accepted,
          vr_Cancelado: data.vr_canceled,
          observacion: data.observation,
          glosa_cartera_recobros: data.gloss_portfolio_recoveries,
          golsa_en_conciliacion: data.conciliation_gloss,
          proceso_juridico: data.legal_process,
          sin_rta_auditoria_ente: data.without_rta_audit,
        }
      }
    },
    downloadExcel() {
      this.$http.post('/recoveries/download-excel', this.formError,
        { responseType: 'blob' }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'recobros.xlsx');
          document.body.appendChild(link);
          link.click();
          this.$swal({
            icon: "success",
            title: "Éxito",
            html: "Archivo descargado éxitosamente!",
          });
        })
    },
    searchData(data) {
      this.$http.post('/recoveries/search', data)
        .then(response => {
          if (response.data.code == 200) {
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
            this.dataTable = response.data.recoveries
          }
          if (response.data.code == 500) {
            this.$swal({
              title: response.data.message,
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-warning',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
        });
    },
    validationForm(id) {
      localize('es', es);
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateUser(id);
        }
      })
    },
    validationFormChangePassword() {
      localize('es', es);
      this.$refs.formChangePassword.validate().then(success => {
        if (success) {
          this.change_password();
        }
      })
    },
    fetchRecoveries() {
      this.showLoadingTable = true
      if (this.id_recovery) {
        this.$http.get('/recoveries/list/' + this.id_recovery, { params: this.tableSettings }).then((response) => {
          this.dataTable = response.data.tableData
          this.totalRows = response.data.totalData
          this.dataMetaCounter()
          this.showLoadingTable = false
        })
      } else {
        this.$http.get('/recoveries/list', { params: this.tableSettings }).then((response) => {
          this.dataTable = response.data.tableData
          this.totalRows = response.data.totalData
          this.dataMetaCounter()
          this.showLoadingTable = false
        })
      }
    },
    dataMetaCounter() {
      const localItemsCount = this.dataTable.length
      this.dataMeta.from = this.tableSettings.perPage * (this.tableSettings.page - 1) + (localItemsCount ? 1 : 0)
      this.dataMeta.to = this.tableSettings.perPage * (this.tableSettings.page - 1) + localItemsCount
      this.dataMeta.of = this.totalRows
    },
    hideModal() {
      this.$refs['my-modal'].hide()
      this.form = {
        id: null,
        recobro_sin_prefijo: null,
        nit: null,
        nonbre: null,
        recobro_con_prefijo: null,
        no_Radicado_Ente: null,
        fecfac: null,
        fecha_prestacion: null,
        edad: null,
        informe_edad: null,
        prescripcion: null,
        corte: null,
        entidad_Responsable_del_Pago: null,
        recibos_cajas_notas: null,
        firma_Correa_y_Cortes: null,
        firma_Bilcoll: null,
        entrega_abogados_ext: null,
        vigencia_bilcoll: null,
        edad_bilcoll: null,
        precispcion_bilcoll: null,
        fehca_entrega_bilcoll: null,
        saldo: null,
        vr_Aceptado: null,
        vr_Cancelado: null,
        observacion: null,
        glosa_cartera_recobros: null,
        golsa_en_conciliacion: null,
        proceso_juridico: null,
        sin_rta_auditoria_ente: null,
      }
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
    
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>